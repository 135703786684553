<template>
    <div class="grid">
        <post-board-topic-navigation
            :active-topic-uid="requestedTopicUid"
            class="col one-third topic-navigation"
        />
        <div class="col two-thirds">
            <div class="slim-timeline">
                <post-time-line
                    :key="timelineTopicUid"
                    :infinite-scroll="true"
                    :topic-uids="searchTopicUids"
                    :loading="isLoading"
                />
            </div>
        </div>
    </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapGetters } from "vuex";
import PostTimeLine from "@web/views/posts/PostTimeLine";
import {
    allTopic,
    subscribedTopic,
} from "@web/store/topic/meta-topics";
import { TOPIC_SUBSCRIPTION_MODULE_NAME } from "@web/store/topic-subscription/topic-subscription";
import {
    GET_TOPIC_UIDS,
    LOADED,
} from "@web/store/topic-subscription/getters";
import PostBoardTopicNavigation from "@web/views/posts/PostBoardTopicNavigation";

/**
 * Displays the timeline with a topic filter navigation as sidebar.
 */
export default {
    name: "PostsBoard",
    components: {
        PostBoardTopicNavigation,
        PostTimeLine,
    },
    props: {
        /** Topic to select for filtering the timeline that might change due to router navigation. */
        selectedTopicUid: String,
    },
    data() {
        return {
            /**
             * State to filter the timeline for a single topic. Changes are synced with a delay to reduce requests due to rapid navigation changes.
             * @type {String}
             * @see debouncedSetTimelineTopicUid
             */
            timelineTopicUid: this.selectedTopicUid || subscribedTopic.uid,
            /**
             * A snapshot of the subscribed topic uids to keep the viewing timeline while changing subscriptions and only update the timline by navigation.
             */
            subscribedTopicUidsSnapshot: [],
        };
    },
    computed: {
        ...mapGetters({
            subscribedTopicUids: TOPIC_SUBSCRIPTION_MODULE_NAME + GET_TOPIC_UIDS,
            subscribedTopicsLoaded: TOPIC_SUBSCRIPTION_MODULE_NAME + LOADED,
        }),
        isLoading() {
            if (this.timelineTopicUid === subscribedTopic.uid) {
                return !this.subscribedTopicsLoaded;
            }
            return false;
        },
        /** Value to immediately change the active topic in the sidebar but not load with a delay. */
        requestedTopicUid() {
            return this.selectedTopicUid || subscribedTopic.uid;
        },
        searchTopicUids() {
            switch (this.timelineTopicUid) {
                case subscribedTopic.uid:
                    return this.subscribedTopicUidsSnapshot;
                case allTopic.uid:
                    return undefined;
                default:
                    return [this.timelineTopicUid];
            }
        },
    },
    watch: {
        /** When router navigation changes, this updates the active topic after a delay in order to reduce request costs on rapid navigation changes. */
        selectedTopicUid(topicUid) {
            this.debouncedSwitchTimelineTopic(topicUid);
        },
        subscribedTopicsLoaded: {
            handler(subscribedTopicsLoaded) {
                if (subscribedTopicsLoaded) {
                    this.subscribedTopicUidsSnapshot = this.subscribedTopicUids;
                }
            },
            immediate: true,
        },
    },
    created() {
        // The debounce function MUST be created once per instance. Otherwise the closure state of the debounce function is shared between all instances of the Vue component.
        this.debouncedSwitchTimelineTopic = debounce(this.switchTimelineTopic, 300);
    },
    methods: {
        /** @param {String} topicUid */
        switchTimelineTopic(topicUid) {
            this.timelineTopicUid = topicUid;
            this.subscribedTopicUidsSnapshot = this.subscribedTopicUids;
        },
        debouncedSwitchTimelineTopic() {
            // placeholder for IDE support, overwritten in created to be debounced per instance.
        },
    },
};
</script>

<style lang="scss" scoped>
.topic-navigation {
    max-width: 18rem;
    // .grid inner spacing - button padding
    margin-left: $spacing-medium - 1.25rem;
}

.slim-timeline {
    max-width: 44rem;
    margin: 0 auto;
}
</style>
