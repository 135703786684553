<template>
    <router-link
        :class="{active}"
        :to="to"
        class="light topics-select-button button focus-outline-none"
    >
        <span class="topics-select-button-label ellipsis">
            <!-- @slot label text of the navigation button -->
            <slot></slot>
        </span>
        <span
            v-if="badge"
            class="unseen-counter d-inline-block background-secondary text-secondary-text small-text centered border-radius-medium px-xxsmall mleft-xxsmall"
        >
            {{ badge }}
        </span>
    </router-link>
</template>

<script>
/**
 * Navigation links for a vertical topic listing usually used within the post board.
 */
export default {
    name: "TopicNavigationLink",
    props: {
        /** Display the navigation in an active state. */
        active: Boolean,
        /**
         * A URL or router location.
         * @type {string | Location}
         * @see https://router.vuejs.org/api/#to
         */
        to: { type: [Object, String], default: "" },
        /** Displayed in an info badge at the end. */
        badge: [String, Number],
    },
};
</script>

<style lang="scss" scoped>
.topics-select-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    + .topics-select-button {
        margin: 0;
    }

    &.light:not(.active):not(:hover) {
        background: none transparent;
    }
}

.topics-select-button-label {
    flex: 0 1 auto;
}

.unseen-counter {
    min-width: 2ch;
    flex: 0 0 auto;
}
</style>

<docs>
Minimum:
```vue
<topic-navigation-link>topic</topic-navigation-link>
```

Active:
```vue
<topic-navigation-link active>topic</topic-navigation-link>
```

As list:
```vue
<template>
    <div>
        <!-- you actually would work with routes instead of @click -->
        <topic-navigation-link
            v-for="i in 3"
            :key="i"
            @click="active = i"
        >
            topic {{ i }}
        </topic-navigation-link>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                active: 1,
            };
        },
    };
</script>
```

Badges:
```vue
<topic-navigation-link badge="1">topic</topic-navigation-link>
<topic-navigation-link badge="99+">topic</topic-navigation-link>
```

Overflow ellipsis:
```vue
<div style="max-width: 20ch;">
    <topic-navigation-link>topictopictopictopic</topic-navigation-link>
    <topic-navigation-link badge="1">topictopictopictopic</topic-navigation-link>
    <topic-navigation-link badge="99+">topictopictopictopictopictopictopictopic</topic-navigation-link>
    <topic-navigation-link active badge="99+">topictopictopictopic</topic-navigation-link>
    <topic-navigation-link>topictopictopictopictopictopictopictopic</topic-navigation-link>
    <topic-navigation-link>topictopictopictopictopictopictopictopic</topic-navigation-link>
</div>
```
</docs>
