<template>
    <div>
        <PostTopicPanel
            v-for="topic in metaTopics"
            :key="topic.uid"
            :active="topic.uid === activeTopicUid"
            :topic="topic"
        />
        <hr class="mtop-small mbottom-small mx-button"/>
        <h4 class="mx-button mbottom-small text-nowrap flex flex-center flex-space-between">
            {{ $t("posts_followed_topics_label") }}
            <router-link
                :to="{name:'topics'}"
                class="mleft-xsmall"
            >
                <icon name="sliders"/>
            </router-link>
        </h4>
        <PostTopicPanel
            v-for="topic in topicStore.state.topics"
            :key="topic.uid"
            :active="topic.uid === activeTopicUid"
            :topic="topic"
        />
        <div v-if="topicStore.state.topics.length === 0 && loading">
            <pu-skeleton
                v-for="i in 5"
                :key="i"
                class="d-block my-small mx-small"
            />
        </div>
        <div
            v-else-if="hasError"
            class="text-small text-warning px-medium"
        >
            {{ $t("topic_subscription_loading_error") }}
        </div>
        <div
            v-else-if="subscribedTopicUids.length === 0"
            class="text-small text-medium-contrast px-medium"
        >
            {{ $t("topic_subscription_empty") }}
        </div>
    </div>
</template>

<script>
import PostTopicPanel from "@web/views/posts/PostTopicPanel";
import {
    getTopics,
    useTopics,
} from "@web/store/topic/topic-local-store";
import { mapGetters } from "vuex";
import { TOPIC_SUBSCRIPTION_MODULE_NAME } from "@web/store/topic-subscription/topic-subscription";
import {
    GET_TOPIC_UIDS,
    HAS_ERROR,
    LOADED,
} from "@web/store/topic-subscription/getters";
import { metaTopics } from "@web/store/topic/meta-topics";
import { i18n } from "@web/i18n";

/**
 * Sidebar to list topics with their proper router links.
 */
export default {
    name: "PostBoardTopicNavigation",
    components: {
        PostTopicPanel,
    },
    props: {
        activeTopicUid: String,
    },
    computed: {
        ...mapGetters({
            subscribedTopicUids: TOPIC_SUBSCRIPTION_MODULE_NAME + GET_TOPIC_UIDS,
            subscribedTopicsHasError: TOPIC_SUBSCRIPTION_MODULE_NAME + HAS_ERROR,
            subscribedTopicsLoaded: TOPIC_SUBSCRIPTION_MODULE_NAME + LOADED,
        }),
        topicStore() {
            return useTopics(this.$store);
        },
        loading() {
            return !this.subscribedTopicsLoaded || this.topicStore.state.fetching;
        },
        metaTopics() {
            return metaTopics(i18n);
        },
        hasError() {
            return this.topicStore.state.hasError || this.subscribedTopicsHasError;
        },
    },
    watch: {
        subscribedTopicUids: {
            handler() {
                this.fetchSubscribedTopics();
            },
            deep: true,
        },
    },
    mounted() {
        this.fetchSubscribedTopics();
    },
    methods: {
        fetchSubscribedTopics() {
            this.topicStore.methods.fetchTopics(() => getTopics(this.subscribedTopicUids));
        },
    },
};
</script>

<style lang="scss" scoped>
// same margin as horizontal button padding
.mx-button {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
}
</style>
