import IVueI18n from "vue-i18n";
import {
    Topic,
    TopicInFirestoreFieldKeys,
} from "@backend/topic/types";

export interface MetaTopic {
    uid: string,
}

export type TopicBase = Pick<Topic, "uid" | TopicInFirestoreFieldKeys.name>;

export const allTopic = Object.freeze<MetaTopic>({ uid: "all" });
export const subscribedTopic = Object.freeze<MetaTopic>({ uid: "subscribed" });

export function metaTopics(i18n: IVueI18n): TopicBase[] {
    return [
        { uid: subscribedTopic.uid, name: i18n.t("subscribed_posts") as string },
        { uid: allTopic.uid, name: i18n.t("all_posts") as string },
    ];
}
