<template>
    <div class="page">
        <div class="container padded">
            <div>
                <h1>{{ $t("posts") }}</h1>
                <PostsBoard :selected-topic-uid="topicUid"/>
            </div>
        </div>
    </div>
</template>

<script>
import PostsBoard from "@web/views/posts/PostsBoard";

export default {
    name: "Posts",
    components: {
        PostsBoard,
    },
    props: {
        topicUid: String,
    },
};
</script>

<style lang="scss" scoped>

</style>
