<template>
    <topic-navigation-link
        :active="active"
        :to="route"
        :badge="unseenContentCount"
    >
        {{ topic.name }}
    </topic-navigation-link>
</template>

<script>
import { mapGetters } from "vuex";
import {
    LIVE_NEW_CONTENT_MODULE_NAME,
    UnseenContentType,
} from "@web/store/live-new-content/live-new-content";
import { GET_COUNT } from "@web/store/live-new-content/getters";
import { TOPIC_SUBSCRIPTION_MODULE_NAME } from "@web/store/topic-subscription/topic-subscription";
import { GET_TOPIC_UIDS } from "@web/store/topic-subscription/getters";
import TopicNavigationLink from "@web/components/posts/TopicNavigationLink";
import {
    allTopic,
    subscribedTopic,
} from "@web/store/topic/meta-topics";

export default {
    name: "PostTopicPanel",
    components: {
        TopicNavigationLink,
    },
    props: {
        /** @type {Topic | MetaTopic} */
        topic: Object,
        /** Explicitly styles an active link.
         *  Note: This is a necessary simple approach because router-link offers no way to add different classes for inactive routes. */
        active: Boolean,
    },
    computed: {
        ...mapGetters({
            getUnseenContentCount: LIVE_NEW_CONTENT_MODULE_NAME + GET_COUNT,
            subscribedTopicUids: TOPIC_SUBSCRIPTION_MODULE_NAME + GET_TOPIC_UIDS,
        }),
        unseenContentCount() {
            switch (this.topic.uid) {
                case allTopic.uid:
                    return this.getUnseenContentCount({ type: UnseenContentType.post });
                case subscribedTopic.uid:
                    return this.getUnseenContentCount({ type: UnseenContentType.post, topics: this.subscribedTopicUids });
                default:
                    return this.getUnseenContentCount({ type: UnseenContentType.post, topic: this.topic.uid });
            }
        },
        route() {
            return { name: "posts-single-topic", params: { topicUid: this.topic.uid } };
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
